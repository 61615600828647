import { createStore } from 'vuex';
import i18n from '../i18n';
import createPersistedState from 'vuex-persistedstate'
import Cookies from 'js-cookie'
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

// import { auth } from "./auth.module";
import { referral } from "./referral.module";

export default new createStore({
    state: {
        user: null,
        // api_url: 'https://api.tfcapital.me/v1/website/',
        api_url: 'https://devapi.tfcapital.me:8443/v1/website/',
        // api_url: 'http://localhost:8443/v1/website/',
        layout: 'auth',
        is_show_sidebar: true,
        is_show_search: false,
        is_dark_mode: false,
        dark_mode: 'dark',
        locale: null,
        menu_style: 'horizantal',
        layout_style: 'full',
        countryList: [
            { code: 'zh', name: 'Chinese' },
            { code: 'da', name: 'Danish' },
            { code: 'en', name: 'English' },
            { code: 'fr', name: 'French' },
            { code: 'de', name: 'German' },
            { code: 'el', name: 'Greek' },
            { code: 'hu', name: 'Hungarian' },
            { code: 'it', name: 'Italian' },
            { code: 'ja', name: 'Japanese' },
            { code: 'pl', name: 'Polish' },
            { code: 'pt', name: 'Portuguese' },
            { code: 'ru', name: 'Russian' },
            { code: 'es', name: 'Spanish' },
            { code: 'sv', name: 'Swedish' },
            { code: 'tr', name: 'Turkish' },
        ],
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setAPI(state, api_url) {
            state.api_url = api_url;
        },
        setLayout(state, payload) {
            state.layout = payload;
        },
        toggleSideBar(state, value) {
            state.is_show_sidebar = value;
        },
        toggleSearch(state, value) {
            state.is_show_search = value;
        },
        toggleLocale(state, value) {
            value = value || 'en';
            i18n.global.locale = value;
            localStorage.setItem('i18n_locale', value);
            state.locale = value;
        },

        toggleDarkMode(state, value) {
            //light|dark|system
            value = value || 'light';
            localStorage.setItem('dark_mode', value);
            state.dark_mode = value;
            if (value == 'light') {
                state.is_dark_mode = false;
            } else if (value == 'dark') {
                state.is_dark_mode = true;
            } else if (value == 'system') {
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    state.is_dark_mode = true;
                } else {
                    state.is_dark_mode = false;
                }
            }

            if (state.is_dark_mode) {
                document.querySelector('body').classList.add('dark');
            } else {
                document.querySelector('body').classList.remove('dark');
            }
        },

        toggleMenuStyle(state, value) {
            //horizontal|vertical|collapsible-vertical
            value = value || '';
            localStorage.setItem('menu_style', value);
            state.menu_style = value;
            if (!value || value === 'vertical') {
                state.is_show_sidebar = true;
            } else if (value === 'collapsible-vertical') {
                state.is_show_sidebar = false;
            }
        },

        toggleLayoutStyle(state, value) {
            //boxed-layout|large-boxed-layout|full
            value = value || '';
            localStorage.setItem('layout_style', value);
            state.layout_style = value;
        },
    },
    getters: {
        layout(state) {
            return state.layout;
        },
    },
    actions: {

    },
    modules: {
        // auth,
        referral
    },
    plugins: [
        createPersistedState({
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })
        // createPersistedState({
        //     storage: {
        //         getItem: (key) => {
        //             // See https://nuxtjs.org/guide/plugins/#using-process-flags
        //             return Cookies.get(key);
        //         },
        //         // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
        //         setItem: (key, value) =>
        //             Cookies.set(key, value, { expires: 1, secure: true }),
        //         removeItem: key => Cookies.remove(key)
        //     }
        // })
    ]
});
